import { motion } from "framer-motion"

const Truck = () => {
    return (
        <svg viewBox="0 0 1572 489" fill="none" xmlns="http://www.w3.org/2000/svg">

            <path d="M1053.96 33.6714C922.743 95.6831 965.573 268.539 970.932 286.585C976.292 304.631 1001.35 354.583 1029.62 390.586C1057.88 426.588 1127.79 483.18 1258.37 488.695C1388.95 494.21 1529.42 415.39 1547.68 357.258C1565.94 299.126 1594.41 93.5532 1536.7 64.4735C1488.1 42.3196 1447.64 79.509 1393.66 44.5518C1339.68 9.59456 1185.17 -28.3403 1053.96 33.6714Z" fill="#C9E3B6"/>
            <path d="M1020.41 101.446H890.047C884.89 101.446 880.709 105.627 880.709 110.786V209.798C880.709 214.956 884.89 219.138 890.047 219.138H1020.41C1025.57 219.138 1029.75 214.956 1029.75 209.798V110.786C1029.75 105.627 1025.57 101.446 1020.41 101.446Z" fill="#D8D8D8"/>
            <path d="M1433.02 81.6316H1152.31C1134.18 81.6316 1119.49 96.3282 1119.49 114.457V399.245C1119.49 417.374 1134.18 432.071 1152.31 432.071H1433.02C1451.14 432.071 1465.84 417.374 1465.84 399.245V114.457C1465.84 96.3282 1451.14 81.6316 1433.02 81.6316Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1152.31 84.4118C1135.72 84.4118 1122.27 97.8637 1122.27 114.457V399.245C1122.27 415.839 1135.72 429.291 1152.31 429.291H1433.02C1449.61 429.291 1463.06 415.839 1463.06 399.245V114.457C1463.06 97.8637 1449.61 84.4118 1433.02 84.4118H1152.31ZM1116.71 114.457C1116.71 94.7928 1132.65 78.8514 1152.31 78.8514H1433.02C1452.68 78.8514 1468.62 94.7928 1468.62 114.457V399.245C1468.62 418.91 1452.68 434.851 1433.02 434.851H1152.31C1132.65 434.851 1116.71 418.91 1116.71 399.245V114.457Z" fill="#BCB7B1"/>
            <path d="M914.582 103.245L1007.68 103.252Z" fill="#D40000"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M912.887 103.245C912.887 102.308 913.646 101.55 914.582 101.55L1007.68 101.557C1008.62 101.557 1009.38 102.316 1009.38 103.252C1009.38 104.189 1008.62 104.948 1007.68 104.948L914.581 104.94C913.645 104.94 912.887 104.181 912.887 103.245Z" fill="#C1C0BF"/>
            <path d="M914.584 217.468L1006.93 217.418Z" fill="#D40000"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1008.63 217.417C1008.63 218.353 1007.87 219.113 1006.93 219.113L914.584 219.164C913.648 219.164 912.889 218.406 912.889 217.469C912.888 216.533 913.647 215.774 914.583 215.773L1006.93 215.723C1007.87 215.722 1008.63 216.481 1008.63 217.417Z" fill="#C1C0BF"/>
            <path d="M1151.31 81.6459H1028.31C1018.57 81.6459 1010.68 89.5443 1010.68 99.2875V235.523C1010.68 245.266 1018.57 253.164 1028.31 253.164H1151.31C1161.05 253.164 1168.95 245.266 1168.95 235.523V99.2875C1168.95 89.5443 1161.05 81.6459 1151.31 81.6459Z" fill="#DCD4D4"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1028.31 84.4261C1020.11 84.4261 1013.46 91.0797 1013.46 99.2875V235.523C1013.46 243.73 1020.11 250.384 1028.31 250.384H1151.31C1159.52 250.384 1166.17 243.73 1166.17 235.523V99.2875C1166.17 91.0797 1159.52 84.4261 1151.31 84.4261H1028.31ZM1007.9 99.2875C1007.9 88.0089 1017.04 78.8657 1028.31 78.8657H1151.31C1162.59 78.8657 1171.73 88.0089 1171.73 99.2875V235.523C1171.73 246.801 1162.59 255.944 1151.31 255.944H1028.31C1017.04 255.944 1007.9 246.801 1007.9 235.523V99.2875Z" fill="#BCB7B1"/>
            <path d="M1189.44 84.3621H1122.22V291.669H1189.44V84.3621Z" fill="white"/>
            <path d="M1011.72 117.578H1001.6C1001.4 117.578 1001.23 117.741 1001.23 117.941V202.786C1001.23 202.987 1001.4 203.149 1001.6 203.149H1011.72C1011.92 203.149 1012.08 202.987 1012.08 202.786V117.941C1012.08 117.741 1011.92 117.578 1011.72 117.578Z" fill="white"/>
            <path d="M1012.02 113.655L1012.18 205.048Z" fill="#D7D1CE"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1012.02 112.735C1012.53 112.734 1012.94 113.145 1012.94 113.654L1013.1 205.046C1013.1 205.554 1012.69 205.967 1012.18 205.968C1011.67 205.969 1011.26 205.558 1011.26 205.049L1011.1 113.657C1011.1 113.149 1011.51 112.736 1012.02 112.735Z" fill="#BCB7B1"/>
            <path d="M1010.71 203.019L1020.89 202.953Z" fill="#D7D1CE"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1023.67 202.935C1023.68 204.471 1022.44 205.723 1020.91 205.733L1010.72 205.799C1009.19 205.809 1007.94 204.572 1007.93 203.037C1007.92 201.501 1009.15 200.249 1010.69 200.239L1020.87 200.173C1022.4 200.163 1023.66 201.4 1023.67 202.935Z" fill="#BCB7B1"/>
            <path d="M1010.65 116.792L1020.83 116.727Z" fill="#D7D1CE"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M1023.61 116.709C1023.62 118.244 1022.39 119.497 1020.85 119.507L1010.67 119.572C1009.13 119.582 1007.88 118.346 1007.87 116.81C1007.86 115.275 1009.1 114.022 1010.63 114.012L1020.81 113.946C1022.35 113.937 1023.6 115.173 1023.61 116.709Z" fill="#BCB7B1"/>
            <path d="M1089.25 58.2222C1103.58 58.2222 1115.19 46.6047 1115.19 32.2739C1115.19 17.9431 1103.58 6.32568 1089.25 6.32568C1074.92 6.32568 1063.31 17.9431 1063.31 32.2739C1063.31 46.6047 1074.92 58.2222 1089.25 58.2222Z" fill="#76BC3A"/>
            <path d="M1049.94 358.896C1064.27 358.896 1075.88 347.279 1075.88 332.948C1075.88 318.617 1064.27 307 1049.94 307C1035.61 307 1024 318.617 1024 332.948C1024 347.279 1035.61 358.896 1049.94 358.896Z" fill="#76BC3A"/>
            <path d="M1533.31 268.756C1547.64 268.756 1559.25 257.139 1559.25 242.808C1559.25 228.477 1547.64 216.86 1533.31 216.86C1518.98 216.86 1507.37 228.477 1507.37 242.808C1507.37 257.139 1518.98 268.756 1533.31 268.756Z" fill="#76BC3A"/>
            <path d="M1514.21 141.005C1528.54 141.005 1540.16 129.387 1540.16 115.056C1540.16 100.725 1528.54 89.108 1514.21 89.108C1499.89 89.108 1488.27 100.725 1488.27 115.056C1488.27 129.387 1499.89 141.005 1514.21 141.005Z" fill="#76BC3A"/>
            <path d="M1515.36 407.875C1529.69 407.875 1541.3 396.258 1541.3 381.927C1541.3 367.596 1529.69 355.979 1515.36 355.979C1501.04 355.979 1489.42 367.596 1489.42 381.927C1489.42 396.258 1501.04 407.875 1515.36 407.875Z" fill="#76BC3A"/>
            <path d="M-52 102.146H889.304V220.767H-52V102.146Z" fill="#FFFDF5"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M889.304 105.853H-52V102.146H889.304V105.853ZM889.304 217.06H-52V220.767H889.304V217.06Z" fill="#D8D8D8"/>
        
            <motion.g initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 2 }}>
                <path d="M1442.24 413.028V265.626C1442.24 265.098 1441.82 264.669 1441.29 264.669H1401.73C1401.2 264.669 1400.77 265.098 1400.77 265.626V413.028C1400.77 413.556 1401.2 413.985 1401.73 413.985H1441.29C1441.82 413.985 1442.24 413.556 1442.24 413.028Z" fill="#F3EFDD"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M1441.51 265.626C1441.51 265.5 1441.41 265.398 1441.29 265.398H1401.73C1401.6 265.398 1401.5 265.5 1401.5 265.626V413.028C1401.5 413.154 1401.6 413.256 1401.73 413.256H1441.29C1441.41 413.256 1441.51 413.153 1441.51 413.028V265.626ZM1441.29 263.939C1442.22 263.939 1442.97 264.695 1442.97 265.626V413.028C1442.97 413.959 1442.22 414.714 1441.29 414.714H1401.73C1400.8 414.714 1400.04 413.959 1400.04 413.028V265.626C1400.04 264.695 1400.8 263.939 1401.73 263.939H1441.29Z" fill="#B9AE8D"/>
            </motion.g>
            <motion.g initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 2 }}>
                <path d="M1399.56 413.028V265.626C1399.56 265.098 1399.13 264.669 1398.6 264.669H1359.04C1358.52 264.669 1358.09 265.098 1358.09 265.626V413.028C1358.09 413.556 1358.52 413.985 1359.04 413.985H1398.6C1399.13 413.985 1399.56 413.556 1399.56 413.028Z" fill="#F3EFDD"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M1398.83 265.626C1398.83 265.5 1398.73 265.398 1398.6 265.398H1359.04C1358.92 265.398 1358.82 265.5 1358.82 265.626V413.028C1358.82 413.153 1358.92 413.256 1359.04 413.256H1398.6C1398.73 413.256 1398.83 413.154 1398.83 413.028V265.626ZM1398.6 263.939C1399.53 263.939 1400.29 264.695 1400.29 265.626V413.028C1400.29 413.959 1399.53 414.714 1398.6 414.714H1359.04C1358.11 414.714 1357.36 413.959 1357.36 413.028V265.626C1357.36 264.695 1358.11 263.939 1359.04 263.939H1398.6Z" fill="#B9AE8D"/>
            </motion.g>
            <motion.g initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 2 }}>
                <path d="M1346.89 390.865H1161.27C1160.98 390.865 1160.74 391.1 1160.74 391.389V413.028C1160.74 413.317 1160.98 413.551 1161.27 413.551H1346.89C1347.18 413.551 1347.42 413.317 1347.42 413.028V391.389C1347.42 391.1 1347.18 390.865 1346.89 390.865Z" fill="#E2D7AE"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M1161.35 391.469V412.947H1346.81V391.469H1161.35ZM1160.14 391.389C1160.14 390.766 1160.64 390.261 1161.27 390.261H1346.89C1347.52 390.261 1348.02 390.766 1348.02 391.389V413.027C1348.02 413.65 1347.52 414.155 1346.89 414.155H1161.27C1160.64 414.155 1160.14 413.65 1160.14 413.027V391.389Z" fill="#B9AE8D"/>
            </motion.g>
            <motion.g initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 2 }}>
                <path d="M1346.91 368.654H1161.28C1160.99 368.654 1160.76 368.888 1160.76 369.177V390.816C1160.76 391.105 1160.99 391.34 1161.28 391.34H1346.91C1347.2 391.34 1347.43 391.105 1347.43 390.816V369.177C1347.43 368.888 1347.2 368.654 1346.91 368.654Z" fill="#E2D7AE"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M1161.36 369.257V390.736H1346.83V369.257H1161.36ZM1160.15 369.177C1160.15 368.555 1160.66 368.05 1161.28 368.05H1346.91C1347.53 368.05 1348.04 368.555 1348.04 369.177V390.816C1348.04 391.438 1347.53 391.943 1346.91 391.943H1161.28C1160.66 391.943 1160.15 391.438 1160.15 390.816V369.177Z" fill="#B9AE8D"/>
            </motion.g>

            <motion.g initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 2.5 }}>
                <path d="M1347.14 346.791H1161.51C1161.22 346.791 1160.99 347.025 1160.99 347.314V368.953C1160.99 369.242 1161.22 369.477 1161.51 369.477H1347.14C1347.43 369.477 1347.67 369.242 1347.67 368.953V347.314C1347.67 347.025 1347.43 346.791 1347.14 346.791Z" fill="#E2D7AE"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M1161.59 347.394V368.873H1347.06V347.394H1161.59ZM1160.39 347.314C1160.39 346.691 1160.89 346.187 1161.51 346.187H1347.14C1347.77 346.187 1348.27 346.691 1348.27 347.314V368.953C1348.27 369.575 1347.77 370.08 1347.14 370.08H1161.51C1160.89 370.08 1160.39 369.575 1160.39 368.953V347.314Z" fill="#B9AE8D"/>
            </motion.g>
            <motion.g initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 2.5 }}>
                <path d="M1345.29 355.741H1163.63C1163.34 355.741 1163.11 355.976 1163.11 356.265V377.91C1163.11 378.199 1163.34 378.434 1163.63 378.434H1345.29C1345.58 378.434 1345.81 378.199 1345.81 377.91V356.265C1345.81 355.976 1345.58 355.741 1345.29 355.741Z" fill="#F3EFDD"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M1163.7 356.338V377.837H1345.21V356.338H1163.7ZM1162.51 356.265C1162.51 355.646 1163.01 355.145 1163.63 355.145H1345.29C1345.9 355.145 1346.41 355.646 1346.41 356.265V377.91C1346.41 378.529 1345.9 379.03 1345.29 379.03H1163.63C1163.01 379.03 1162.51 378.529 1162.51 377.91V356.265Z" fill="#B9AE8D"/>
            </motion.g>
            <motion.g initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 2.5 }}>
                <path d="M1345.29 379.031H1163.63C1163.34 379.031 1163.11 379.265 1163.11 379.555V401.199C1163.11 401.488 1163.34 401.723 1163.63 401.723H1345.29C1345.58 401.723 1345.81 401.488 1345.81 401.199V379.555C1345.81 379.265 1345.58 379.031 1345.29 379.031Z" fill="#F3EFDD"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M1163.7 379.628V401.126H1345.21V379.628H1163.7ZM1162.51 379.554C1162.51 378.935 1163.01 378.433 1163.63 378.433H1345.29C1345.91 378.433 1346.41 378.935 1346.41 379.554V401.199C1346.41 401.818 1345.91 402.32 1345.29 402.32H1163.63C1163.01 402.32 1162.51 401.818 1162.51 401.199V379.554Z" fill="#B9AE8D"/>
            </motion.g>
            <motion.g initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 2.5 }}>
                <path d="M1440.7 127.325H1255.07C1254.78 127.325 1254.54 127.559 1254.54 127.848V149.487C1254.54 149.776 1254.78 150.011 1255.07 150.011H1440.7C1440.99 150.011 1441.22 149.776 1441.22 149.487V127.848C1441.22 127.559 1440.99 127.325 1440.7 127.325Z" fill="#E2D7AE"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M1255.15 127.928V149.407H1440.62V127.928H1255.15ZM1253.94 127.848C1253.94 127.226 1254.45 126.721 1255.07 126.721H1440.7C1441.32 126.721 1441.82 127.226 1441.82 127.848V149.487C1441.82 150.11 1441.32 150.614 1440.7 150.614H1255.07C1254.45 150.614 1253.94 150.11 1253.94 149.487V127.848Z" fill="#B9AE8D"/>
            </motion.g>

            <motion.g initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 3 }}>
                <path d="M1440.73 151.251H1255.1C1254.81 151.251 1254.57 151.486 1254.57 151.775V173.414C1254.57 173.703 1254.81 173.937 1255.1 173.937H1440.73C1441.02 173.937 1441.25 173.703 1441.25 173.414V151.775C1441.25 151.486 1441.02 151.251 1440.73 151.251Z" fill="#E2D7AE"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M1255.18 151.855V173.334H1440.65V151.855H1255.18ZM1253.97 151.775C1253.97 151.153 1254.48 150.648 1255.1 150.648H1440.73C1441.35 150.648 1441.85 151.153 1441.85 151.775V173.414C1441.85 174.037 1441.35 174.541 1440.73 174.541H1255.1C1254.48 174.541 1253.97 174.037 1253.97 173.414V151.775Z" fill="#B9AE8D"/>
            </motion.g>
            <motion.g initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 3 }}>
                <path d="M1440.73 175.205H1255.1C1254.81 175.205 1254.57 175.439 1254.57 175.728V197.367C1254.57 197.656 1254.81 197.891 1255.1 197.891H1440.73C1441.02 197.891 1441.25 197.656 1441.25 197.367V175.728C1441.25 175.439 1441.02 175.205 1440.73 175.205Z" fill="#E2D7AE"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M1255.18 175.809V197.287H1440.65V175.809H1255.18ZM1253.97 175.729C1253.97 175.106 1254.48 174.601 1255.1 174.601H1440.73C1441.35 174.601 1441.85 175.106 1441.85 175.729V197.367C1441.85 197.99 1441.35 198.495 1440.73 198.495H1255.1C1254.48 198.495 1253.97 197.99 1253.97 197.367V175.729Z" fill="#B9AE8D"/>
            </motion.g>
            <motion.g initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 3 }}>
                <path d="M1438.49 139.518H1256.84C1256.55 139.518 1256.31 139.752 1256.31 140.042V161.686C1256.31 161.975 1256.55 162.21 1256.84 162.21H1438.49C1438.78 162.21 1439.02 161.975 1439.02 161.686V140.042C1439.02 139.752 1438.78 139.518 1438.49 139.518Z" fill="#F3EFDD"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M1256.91 140.115V161.613H1438.42V140.115H1256.91ZM1255.72 140.042C1255.72 139.423 1256.22 138.921 1256.84 138.921H1438.49C1439.11 138.921 1439.61 139.423 1439.61 140.042V161.686C1439.61 162.305 1439.11 162.807 1438.49 162.807H1256.84C1256.22 162.807 1255.72 162.305 1255.72 161.686V140.042Z" fill="#B9AE8D"/>
            </motion.g>
            <motion.g initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 3 }}>
                <path d="M1438.74 163.728H1257.08C1256.79 163.728 1256.56 163.962 1256.56 164.251V185.896C1256.56 186.185 1256.79 186.42 1257.08 186.42H1438.74C1439.03 186.42 1439.26 186.185 1439.26 185.896V164.251C1439.26 163.962 1439.03 163.728 1438.74 163.728Z" fill="#F3EFDD"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M1257.16 164.325V185.822H1438.66V164.325H1257.16ZM1255.96 164.251C1255.96 163.632 1256.46 163.13 1257.08 163.13H1438.74C1439.36 163.13 1439.86 163.632 1439.86 164.251V185.896C1439.86 186.515 1439.36 187.017 1438.74 187.017H1257.08C1256.46 187.017 1255.96 186.515 1255.96 185.896V164.251Z" fill="#B9AE8D"/>
            </motion.g>
            
           














       
        {/* Truck start */}
        <motion.g animate={{ x: 850 }} transition={{ duration: 2 }}>
        
            <path d="M322.909 134.058H219.249C217.431 134.058 215.957 135.532 215.957 137.351V190.814C215.957 192.633 217.431 194.107 219.249 194.107H322.909C324.727 194.107 326.201 192.633 326.201 190.814V137.351C326.201 135.532 324.727 134.058 322.909 134.058Z" fill="#DFD5CD"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M219.25 134.985C217.943 134.985 216.884 136.044 216.884 137.351V190.814C216.884 192.121 217.943 193.18 219.25 193.18H322.91C324.216 193.18 325.275 192.121 325.275 190.814V137.351C325.275 136.044 324.216 134.985 322.91 134.985H219.25ZM215.031 137.351C215.031 135.02 216.92 133.131 219.25 133.131H322.91C325.24 133.131 327.128 135.02 327.128 137.351V190.814C327.128 193.144 325.24 195.034 322.91 195.034H219.25C216.92 195.034 215.031 193.144 215.031 190.814V137.351Z" fill="#948980"/>
            <path d="M354.171 139.104H332.935C331.727 139.104 330.748 140.084 330.748 141.292V186.874C330.748 188.082 331.727 189.062 332.935 189.062H354.171C355.379 189.062 356.358 188.082 356.358 186.874V141.292C356.358 140.084 355.379 139.104 354.171 139.104Z" fill="#D91313"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M332.935 141.101C332.829 141.101 332.744 141.186 332.744 141.292V186.874C332.744 186.98 332.829 187.065 332.935 187.065H354.171C354.277 187.065 354.362 186.98 354.362 186.874V141.292C354.362 141.186 354.277 141.101 354.171 141.101H332.935ZM328.752 141.292C328.752 138.981 330.625 137.108 332.935 137.108H354.171C356.481 137.108 358.354 138.981 358.354 141.292V186.874C358.354 189.185 356.481 191.058 354.171 191.058H332.935C330.625 191.058 328.752 189.185 328.752 186.874V141.292Z" fill="#D91313" fillOpacity="0.991525"/>
            <path d="M360.275 149.092C360.275 145.62 357.541 142.806 354.168 142.806C350.795 142.806 348.061 145.62 348.061 149.092V179.459C348.061 182.93 350.795 185.745 354.168 185.745C357.541 185.745 360.275 182.93 360.275 179.459V149.092Z" fill="#D91313"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M354.169 146.853C353.139 146.853 352.108 147.745 352.108 149.092V179.459C352.108 180.805 353.139 181.698 354.169 181.698C355.199 181.698 356.23 180.805 356.23 179.459V149.092C356.23 147.745 355.199 146.853 354.169 146.853ZM344.016 149.092C344.016 143.495 348.453 138.759 354.169 138.759C359.885 138.759 364.322 143.495 364.322 149.092V179.459C364.322 185.056 359.885 189.792 354.169 189.792C348.453 189.792 344.016 185.056 344.016 179.459V149.092Z" fill="#D91313"/>
            <path d="M353.793 142.999C350.892 147.567 350.411 146.964 350.411 146.964C352.233 158.183 351.734 169.244 350.059 180.462C350.059 180.462 352.089 181.205 353.312 184.717C357.701 174.01 358.607 156.734 353.793 142.999H353.793Z" fill="#440606"/>
            <path d="M341.646 145.523L344.476 145.392L348.215 145.564C348.215 145.564 348.533 145.375 348.828 144.92C349.96 143.175 351.493 141.928 351.044 141.923C349.301 141.902 341.191 141.923 341.191 141.923C341.584 144.132 341.469 145.502 341.469 145.502L341.646 145.523Z" fill="#440606"/>
            <path d="M339.927 145.523L339.826 141.964L332.854 142.047C332.854 142.047 336.013 145.553 336.491 145.523C337.733 145.444 338.871 145.505 339.927 145.523L339.927 145.523Z" fill="#440606"/>
            <path d="M341.668 181.661L344.497 181.791L348.236 181.619C348.236 181.619 348.555 181.808 348.849 182.263C349.981 184.008 351.514 185.255 351.066 185.261C349.323 185.281 341.213 185.261 341.213 185.261C341.606 183.052 341.491 181.681 341.491 181.681L341.668 181.661Z" fill="#440606"/>
            <path d="M339.949 181.659L339.848 185.218L332.875 185.135C332.875 185.135 336.034 181.629 336.513 181.659C337.754 181.738 338.893 181.677 339.949 181.659L339.949 181.659Z" fill="#440606"/>
            <path d="M363.154 151.936C363.779 151.576 363.838 149.535 363.286 147.377C362.734 145.219 361.78 143.76 361.156 144.12C360.532 144.48 360.473 146.521 361.025 148.679C361.576 150.838 362.53 152.296 363.154 151.936Z" fill="#E2BB14"/>
            <path d="M363.154 176.905C363.779 177.265 363.838 179.306 363.286 181.464C362.734 183.623 361.78 185.081 361.156 184.721C360.532 184.362 360.473 182.32 361.025 180.162C361.576 178.004 362.53 176.546 363.154 176.905Z" fill="#E2BB14"/>
            <path d="M352.972 194.834C354.791 192.921 355.539 190.7 354.643 189.874C353.746 189.047 351.545 189.927 349.726 191.84C347.906 193.752 347.158 195.973 348.055 196.8C348.951 197.627 351.153 196.747 352.972 194.834Z" fill="#D91313"/>
            <path d="M352.784 133.958C354.604 135.871 355.352 138.092 354.455 138.919C353.559 139.746 351.357 138.866 349.538 136.953C347.719 135.04 346.971 132.819 347.867 131.992C348.764 131.165 350.965 132.046 352.784 133.958Z" fill="#D91313"/>


            <motion.g initial={{ opacity: 1 }} animate={{ opacity: 0 }} transition={{ delay: 3 }}>
                <path d="M322.197 142.424H219.315C219.128 142.424 218.977 142.575 218.977 142.762V156.75C218.977 156.937 219.128 157.088 219.315 157.088H322.197C322.384 157.088 322.535 156.937 322.535 156.75V142.762C322.535 142.575 322.384 142.424 322.197 142.424Z" fill="#F3EFDD"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M219.788 143.237V156.275H321.721V143.237H219.788ZM218.162 142.762C218.162 142.126 218.678 141.61 219.314 141.61H322.195C322.831 141.61 323.347 142.126 323.347 142.762V156.75C323.347 157.386 322.831 157.902 322.195 157.902H219.314C218.678 157.902 218.162 157.386 218.162 156.75V142.762Z" fill="#B9AE8D"/>
            </motion.g>
            <motion.g initial={{ opacity: 1 }} animate={{ opacity: 0 }} transition={{ delay: 3 }}>
                <path d="M322.232 157.812H219.35C219.163 157.812 219.012 157.964 219.012 158.151V172.138C219.012 172.325 219.163 172.477 219.35 172.477H322.232C322.419 172.477 322.57 172.325 322.57 172.138V158.151C322.57 157.964 322.419 157.812 322.232 157.812Z" fill="#F3EFDD"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M219.825 158.626V171.664H321.758V158.626H219.825ZM218.199 158.151C218.199 157.515 218.715 156.999 219.351 156.999H322.232C322.868 156.999 323.384 157.515 323.384 158.151V172.138C323.384 172.775 322.868 173.29 322.232 173.29H219.351C218.715 173.29 218.199 172.775 218.199 172.138V158.151Z" fill="#B9AE8D"/>
            </motion.g>
            <motion.g initial={{ opacity: 1 }} animate={{ opacity: 0 }} transition={{ delay: 2.5 }}>
                <path d="M322.232 173.84H219.35C219.163 173.84 219.012 173.991 219.012 174.178V188.166C219.012 188.353 219.163 188.505 219.35 188.505H322.232C322.419 188.505 322.57 188.353 322.57 188.166V174.178C322.57 173.991 322.419 173.84 322.232 173.84Z" fill="#F3EFDD"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M219.825 174.653V187.691H321.758V174.653H219.825ZM218.199 174.178C218.199 173.542 218.715 173.027 219.351 173.027H322.232C322.868 173.027 323.384 173.542 323.384 174.178V188.166C323.384 188.802 322.868 189.318 322.232 189.318H219.351C218.715 189.318 218.199 188.802 218.199 188.166V174.178Z" fill="#B9AE8D"/>
            </motion.g>
            <motion.g initial={{ opacity: 1 }} animate={{ opacity: 0 }} transition={{ delay: 2.5 }}>
                <path d="M321.175 165.407H221.736C221.551 165.407 221.4 165.557 221.4 165.743V179.619C221.4 179.805 221.551 179.955 221.736 179.955H321.175C321.361 179.955 321.511 179.805 321.511 179.619V165.743C321.511 165.557 321.361 165.407 321.175 165.407Z" fill="#E2D7AE"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M222.198 166.203V179.159H320.716V166.203H222.198ZM220.605 165.743C220.605 165.118 221.112 164.611 221.737 164.611H321.176C321.802 164.611 322.308 165.118 322.308 165.743V179.619C322.308 180.244 321.802 180.751 321.176 180.751H221.737C221.112 180.751 220.605 180.244 220.605 179.619V165.743Z" fill="#B9AE8D"/>
            </motion.g>
            <motion.g initial={{ opacity: 1 }} animate={{ opacity: 0 }} transition={{ delay: 2 }}>
                <path d="M320.915 149.781H221.476C221.291 149.781 221.141 149.932 221.141 150.117V163.993C221.141 164.179 221.291 164.329 221.476 164.329H320.915C321.101 164.329 321.251 164.179 321.251 163.993V150.117C321.251 149.932 321.101 149.781 320.915 149.781Z" fill="#E2D7AE"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M221.936 150.578V163.533H320.454V150.578H221.936ZM220.344 150.117C220.344 149.492 220.851 148.985 221.476 148.985H320.915C321.54 148.985 322.047 149.492 322.047 150.117V163.993C322.047 164.619 321.54 165.126 320.915 165.126H221.476C220.851 165.126 220.344 164.619 220.344 163.993V150.117Z" fill="#B9AE8D"/>
            </motion.g>
            <motion.g initial={{ opacity: 1 }} animate={{ opacity: 0 }} transition={{ delay: 2 }}>
                <path d="M319.565 157.239H222.015C221.828 157.239 221.676 157.39 221.676 157.578V171.605C221.676 171.792 221.828 171.944 222.015 171.944H319.565C319.753 171.944 319.904 171.792 319.904 171.605V157.578C319.904 157.39 319.753 157.239 319.565 157.239Z" fill="#F3EFDD"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M222.469 158.032V171.151H319.111V158.032H222.469ZM220.883 157.578C220.883 156.952 221.39 156.445 222.015 156.445H319.565C320.19 156.445 320.697 156.952 320.697 157.578V171.604C320.697 172.23 320.19 172.737 319.565 172.737H222.015C221.39 172.737 220.883 172.23 220.883 171.604V157.578Z" fill="#B9AE8D"/>
            </motion.g>
            
            
            
            
            

        </motion.g>
       
        {/* Truck end */}

        </svg>

        

    )
}

export default Truck